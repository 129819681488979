export default {
    metrika: {
        id: 26812653
    },

    defaults: {
        hooks: {
            onready: function () {},
            onshare: function () {},
            onClosePopup: function () {}
        },

        theme: {
            bare: false,
            curtain: false,
            // Доработка для превью в Конструкторе Лендингов https://st.yandex-team.ru/SHARE-564#5ef47c5cb93c9f4dc7e064c3
            forceCurtain: false,
            copy: 'last',
            lang: 'ru',
            limit: false,
            nonce: '',
            moreButtonType: '',
            popupPosition: 'inner',
            popupDirection: 'bottom',
            colorScheme: 'normal',
            shape: 'normal',
            services: 'collections,vkontakte,facebook,twitter',
            messengerContacts: false,
            size: 'm',
            useLinks: false,
            direction: 'horizontal'
        },

        i18n: {
            az: {
                shareButton: 'Paylaşmaq',
                copyLink: 'Əlaqə',
                linkCopied: 'Keçid köçürüldü',
                otherServices: 'Digər',
                pressToCopy: 'Press ctrl+C and Enter to copy',
                sendToMessenger: 'Yandex.Messencerə göndərmək'
            },
            be: {
                shareButton: 'Падзяліцца',
                copyLink: 'Cпасылка',
                linkCopied: 'Спасылка скапіявана',
                otherServices: 'Іншыя',
                pressToCopy: 'Press ctrl+C and Enter to copy',
                sendToMessenger: 'Адправіць у Яндекс.Мэсэнджар'
            },
            en: {
                shareButton: 'Share',
                copyLink: 'Copy link',
                linkCopied: 'Link copied',
                otherServices: 'Other',
                pressToCopy: 'Press ctrl+C and Enter to copy',
                sendToMessenger: 'Send to Yandex.Messenger'
            },
            hy: {
                shareButton: 'Կիսվել',
                copyLink: 'Հղում',
                linkCopied: 'Հղումը պատճենվել է',
                otherServices: 'Այլ',
                pressToCopy: 'Press ctrl+C and Enter to copy',
                sendToMessenger: 'Ուղարկել Yandex.Messenger-ին'
            },
            ka: {
                shareButton: 'გაზიარება',
                copyLink: 'ბმული',
                linkCopied: 'Ბმული დაკოპირებულია',
                otherServices: 'სხვა',
                pressToCopy: 'Press ctrl+C and Enter to copy',
                sendToMessenger: 'Yandex.Messenger-ში გაგზავნა'
            },
            kk: {
                shareButton: 'Бөлісу',
                copyLink: 'Сілтеме',
                linkCopied: 'Сілтеме көшірілді',
                otherServices: 'Басқа',
                pressToCopy: 'Көшіру үшін ctrl+С және enter-ды басыңыз',
                sendToMessenger: 'Яндекс.Мессенджерге жіберу'
            },
            ro: {
                shareButton: 'Distribuie',
                copyLink: 'Link',
                linkCopied: 'Link copiat',
                otherServices: 'Altele',
                pressToCopy: 'Press ctrl+C and Enter to copy',
                // https://st.yandex-team.ru/TRANSLATEADMIN-3335
                sendToMessenger: 'Send to Yandex.Messenger'
            },
            ru: {
                shareButton: 'Поделиться',
                copyLink: 'Скопировать ссылку',
                linkCopied: 'Ссылка скопирована',
                otherServices: 'Другие',
                pressToCopy: 'Чтобы скопировать, нажмите ctrl+С и enter',
                sendToMessenger: 'Отправить в Яндекс.Мессенджер'
            },
            tr: {
                shareButton: 'Paylaş',
                copyLink: 'Bağlantı',
                linkCopied: 'Bağlantı kopyalandı',
                otherServices: 'Diğer',
                pressToCopy: 'Press ctrl+C and Enter to copy',
                sendToMessenger: 'Yandex.Messenger\'a gönder'
            },
            tt: {
                shareButton: 'Уртаклашу',
                copyLink: 'Сылтама',
                linkCopied: 'Сылтама күчереп алынды',
                otherServices: 'Башкалар',
                pressToCopy: 'Press ctrl+C and Enter to copy',
                sendToMessenger: 'Яндекс.Мессенджерга җибәрү'
            },
            uk: {
                shareButton: 'Поділитися',
                copyLink: 'Посилання',
                linkCopied: 'Посилання скопійовано',
                otherServices: 'Інші',
                pressToCopy: 'Press ctrl+C and Enter to copy',
                sendToMessenger: 'Надіслати в Яндекс.Месенджер'
            },
            uz: {
                shareButton: 'Ulashish',
                copyLink: 'Havoladan nusxa olish',
                linkCopied: 'Havola nusxalandi',
                otherServices: 'Boshqalar',
                pressToCopy: 'Nusxa olish uchun ctrl+С va enter tugmalarini bosing',
                sendToMessenger: 'Yandex.Messengerga yuborish'
            }
        },

        content: {
            template: 'default',
            description: '',
            image: '',
            title: window.document.title,
            url: window.location.href
        },

        contentByService: {},

        asPopup: false
    }
};
