export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://vk.com/share.php',
                params: {
                    url: 'url',
                    title: 'title',
                    description: 'description',
                    image: 'image'
                }
            }
        }
    },

    popupDimensions: [550, 420],

    i18n: {
        az: 'VKontakte',
        be: 'ВКонтакте',
        en: 'VKontakte',
        hy: 'VKontakte',
        ka: 'VKontakte',
        kk: 'ВКонтакте',
        ro: 'VKontakte',
        ru: 'ВКонтакте',
        tr: 'VKontakte',
        tt: 'ВКонтакте',
        uk: 'ВКонтакті',
        uz: 'VKontakte'
    },

    color: '#4680c2'
};
