import extend from 'extend';
import get from 'lodash/get';
import set from 'lodash/set';

/**
 * Хранилище для настроек
 *
 * Типичное хранилище с геттером вида `storage.get('deeply.nested.field`'),
 * за исключением небольшой особенности.
 *
 * В геттер можно также передать имя плагина, для которого нужно
 * получить настройку: `storage.get('content.nested.field', 'plugin')`.
 *
 * При доступе к полям вида `content.*` (то есть, расположенных в группе
 * настроек `content`) такой геттер сначала заглянет по адресу
 * `contentByService[plugin].nested.field`. Если значения по такому адресу
 * нет (undefined), то сработает фоллбэк на изначально указанный путь
 * `content.nested.field`.
 *
 * При доступе к остальным полям никакой магии не происходит, даже если
 * указано имя плагина.
 */
export class Storage {
    constructor(...args) {
        this._options = extend(true, {}, ...args);
    }

    merge(options) {
        extend(true, this._options, options);
    }

    get(key, service) {
        if (service && key.match(/^content\./)) {
            const serviceKey = key.replace(/^content\./, `contentByService.${service}.`);
            const serviceValue = get(this._options, serviceKey);

            if (serviceValue !== undefined) {
                return serviceValue;
            }
        }

        return get(this._options, key);
    }
}

/**
 * Создать белый список настроек
 *
 * Функция берёт за основу список настроек по умолчанию и дополняет
 * его настройками, специфичными для отдельных плагинов.
 *
 * @param  {Object} defaults
 * @param  {Object} contentParamsByPlugin
 * @return {Object}
 */
export function createSchema(defaults, contentParamsByPlugin) {
    const schema = extend(true, {}, defaults, {
        contentByService: {}
    });

    Object.keys(contentParamsByPlugin).forEach(pluginName => {
        const contentParams = contentParamsByPlugin[pluginName];

        Object.keys(contentParams).forEach(name => {
            const key = `contentByService.${pluginName}.${name}`;
            const value = contentParams[name];

            set(schema, key, value);
        });
    });

    return schema;
}

/**
 * Свойства полей, указываемых в дата-арибутах
 *
 * Поле `group` отвечает за вложенный объект настроек, в который попадёт
 * это поле. `type` нужен для того, чтобы приводить значения некоторых
 * атрибутов к нужному формату.
 *
 * Неизвестные поля (в том числе, определённые на уровне плагина) получают
 * свойства `_defaults`.
 *
 * @type {Object}
 */
const attrProps = {
    _defaults: { group: 'content', type: 'string' },
    bare: { group: 'theme', type: 'boolean' },
    curtain: { group: 'theme', type: 'boolean' },
    // Доработка для превью в Конструкторе Лендингов https://st.yandex-team.ru/SHARE-564#5ef47c5cb93c9f4dc7e064c3
    forceCurtain: { group: 'theme', type: 'boolean' },
    copy: { group: 'theme', type: 'string' },
    lang: { group: 'theme', type: 'string' },
    limit: { group: 'theme', type: 'string' },
    nonce: { group: 'theme', type: 'string' },
    moreButtonType: { group: 'theme', type: 'string' },
    popupPosition: { group: 'theme', type: 'string' },
    popupDirection: { group: 'theme', type: 'string' },
    colorScheme: { group: 'theme', type: 'string' },
    shape: { group: 'theme', type: 'string' },
    services: { group: 'theme', type: 'string' },
    messengerContacts: { group: 'theme', type: 'boolean' },
    size: { group: 'theme', type: 'string' },
    direction: { group: 'theme', type: 'string' },
    useLinks: { group: 'theme', type: 'boolean' }
};

/**
 * Преобразовать список дата-атрибутов в список настроек
 *
 * Поскольку объект настроек представляет из себя двухуровневый список
 * (настройки разбиты на группы), а атрибуты являются одноуровневым списком,
 * необходимо определить правила преобразования.
 *
 * @param  {Object} dataset
 * @param  {Object} schema
 * @return {Object}
 */
export function fromDataset(dataset) {
    const config = {};

    Object.keys(dataset).forEach(attribute => {
        const [name, service] = attribute.split(':');
        const props = attrProps[name] || attrProps._defaults;
        const { group, type } = props;
        const value = castAttribute(type, dataset[attribute]);
        let key;

        if (service) {
            // Если свойство, заданное для отдельного сервиса,
            // не относится к группе `content`, игнорируем его
            if (group !== 'content') {
                return;
            }

            // То самое магическое поле `contentByService`,
            // о котором знает геттер Storage
            key = `contentByService.${service}.${name}`;
        } else {
            key = `${group}.${name}`;
        }

        set(config, key, value);
    });

    return config;
}

/**
 * Приведение типа дата-атрибута
 *
 * Нужно только для приведения булевых атрибутов
 * к булевому же типу.
 *
 * @param  {String} type
 * @param  {String} value
 * @return {Mixed}
 */
function castAttribute(type, value) {
    switch (type) {
        case 'boolean':
            return value !== undefined;

        default:
            return value;
    }
}

/**
 * Отфильтровать объект по белому списку
 *
 * Возвращает объект с полями из `config`, которые
 * присутствуют в `schema`.
 *
 * При фильтрации полей в объекте `config.contentByService[plugin]`
 * проверятеся их наличие в `schema` не только в одноимённом поле,
 * но и в поле `schema.content`.
 *
 * @param  {Object} config
 * @param  {Object} schema
 * @return {Object}
 */
export function applyWhitelist(config, schema) {
    const filteredConfig = {};

    Object.keys(config).forEach(group => {
        const data = config[group];

        if (typeof data !== 'object' || data === null) {
            return;
        }

        // Для магического поля `contentByService` применяются более сложные
        // правила фильтрации: нужно проверить не только одноимённое поле
        // схемы, но и поле `content`
        if (group === 'contentByService') {
            const services = data;

            Object.keys(services).forEach(service => {
                const fields = services[service];

                if (typeof data !== 'object' || data === null) {
                    return;
                }

                Object.keys(fields).forEach(name => {
                    const value = fields[name];
                    const key = `contentByService.${service}.${name}`;

                    if (get(schema, `content.${name}`) === undefined &&
                        get(schema, `contentByService.${service}.${name}`) === undefined
                    ) {
                        return;
                    }

                    set(filteredConfig, key, value);
                });
            });
        } else {
            const fields = data;

            Object.keys(fields).forEach(name => {
                const value = fields[name];
                const key = `${group}.${name}`;

                if (get(schema, `${group}.${name}`) === undefined) {
                    return;
                }

                set(filteredConfig, key, value);
            });
        }
    });

    return filteredConfig;
}

/**
 * Собрать объект настроек из нескольких источников
 *
 * Существует три источника настроек:
 *
 *   - содержимое поля `defaults` модуля `config.js` (`defaults`)
 *   - дата-атрибуты элемента `.ya-share2` (`dataset`)
 *   - параметры, переданные в функцию `Ya.share2` (`api`)
 *
 * Первый источник (`defaults`) задаётся разработчиками сервиса
 * "share2" в исходном коде. Остальные источники задаются
 * пользователем при настройке автоматической (`dataset`)
 * и ручной (`api`) инициализации блока.
 *
 * Упрощённо процесс построения конечного объекта настроек можно
 * представить в виде последовательного объединения объектов:
 *
 * ```js
 * const config = Object.assign({}, defaults, dataset, api);
 * ```
 *
 * При этом следует учитывать, что из `dataset` и `api` будут
 * скопированы только те поля, что присутствовали в `defaults`.
 * Это предотвращает добавление пользователем новых полей
 * в объект настроек.
 *
 * @param  {Object} contentParams
 * @param  {Object} defaults
 * @param  {Object} dataset
 * @param  {Object} apiOptions
 * @return {Object}
 */
export default function createOptions(contentParams, defaults, dataset, apiOptions = {}) {
    const schema = createSchema(defaults, contentParams);
    const datasetOptions = fromDataset(dataset);

    const filteredDatasetOptions = applyWhitelist(datasetOptions, schema);
    const filteredApiOptions = applyWhitelist(apiOptions, schema);

    return new Storage(schema, filteredDatasetOptions, filteredApiOptions);
}
